import { t } from '@transifex/native';
import {
	ChangeEvent,
	Fragment,
	ReactNode,
	createContext,
	useContext,
	useRef,
	useState,
} from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterQuery, toFilterQuery } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import useMixedState from 'pkg/hooks/useMixedState';
import { useComponentWillUnmount } from 'pkg/hooks/useComponentDidMount';
import * as arrays from 'pkg/arrays';

import { TabBar, Tab } from 'components/tab-bar';
import Icon from 'components/icon';
import { ScrollSpy, Trigger } from 'components/ScrollSpy';
import * as StepModal from 'components/step-modal';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import GroupTree from 'components/user-select-modal/GroupTree';

import { FilterButton } from 'design/button';
import * as Table from 'design/table';

import * as css from './style.css';

const NoOp = (): any => null;

type CurrentTab = 'all' | 'user' | 'admin';

interface UserSelectState {
	search: string;
	setSearch: (search: string) => void;

	currentTab: CurrentTab;
	setCurrentTab: (tab: CurrentTab) => void;

	users: models.user.User[];
	setUsers: (users: models.user.User[]) => void;

	groupIds: number[];
	setGroupIds: (userIds: number[]) => void;
}

const InitialUserSelectState: UserSelectState = {
	search: '',
	setSearch: NoOp,

	currentTab: 'all',
	setCurrentTab: NoOp,

	users: [],
	setUsers: NoOp,

	groupIds: [],
	setGroupIds: NoOp,
};

const UserSelectContext = createContext<UserSelectState>(
	InitialUserSelectState
);

export function useUserSelectState(): UserSelectState {
	return useContext(UserSelectContext);
}

interface UserSelectModalOptions {
	defaultOpen?: boolean;
	defaultActiveTabId?: 'all' | 'user' | 'admin';
	defaultSelectedGroupId?: number;
	ignoredUserIds?: number[];

	title?: string;

	saveLabel?: string;
	onSave?: (users: models.user.User[]) => void;

	cancelLabel?: string;
	onCancel?: () => void;
}

interface UseUserSelectModal {
	open: () => void;
	close: () => void;
	Component: ReactNode;
}

export function useUserSelectModal({
	defaultOpen,
	defaultSelectedGroupId,
	defaultActiveTabId = 'all',
	ignoredUserIds = [],

	title = t('Add users'),

	saveLabel = t('Add users'),
	onSave,

	cancelLabel = t('Cancel'),
	onCancel,
}: UserSelectModalOptions = {}): UseUserSelectModal {
	const organization = useCurrentOrganization();
	const group = useCurrentGroup();

	const [isOpen, setOpen] = useState<boolean>(defaultOpen);

	const initialGroupFilterValues = [defaultSelectedGroupId || group.id];

	const [state, setState] = useMixedState<UserSelectState>({
		...InitialUserSelectState,
		groupIds: initialGroupFilterValues,
	});

	const endpoint =
		state.groupIds.length > 0
			? endpoints.Organizations.ListUsers(organization.id)
			: null;

	const setSearch = (search: Nullable<string>) => setState({ search });
	const setCurrentTab = (currentTab: CurrentTab) => setState({ currentTab });
	const setUsers = (users: models.user.User[]) => setState({ users });
	const setGroupIds = (groupIds: number[]) => setState({ groupIds });

	const open = () => setOpen(true);

	const close = () => {
		reset();
		setOpen(false);
	};

	const reset = () => {
		setState({
			currentTab: 'all',
			users: [],
			groupIds: initialGroupFilterValues,
		});
	};

	const filters: FilterQuery = {};

	if (state.search !== '') {
		filters.name = {
			operator: FilterOperator.Contains,
			values: [state.search],
		};
	}

	if (state.groupIds.length > 0) {
		filters.group_ids = {
			operator: FilterOperator.Includes,
			values: state.groupIds,
		};
	}

	const getRoleFilterValues = () => {
		let values: string[] = [];
		let roles: string[] = [];

		if (state.currentTab === 'user') {
			roles = ['user'];
		} else if (state.currentTab === 'admin') {
			roles = ['admin', 'staff'];
		} else {
			roles = ['user', 'admin', 'staff'];
		}

		if (state.groupIds.length > 0) {
			values = roles
				.map((role) => state.groupIds.map((id) => `${role}:${id}`))
				.flat();
		} else {
			values = roles;
		}

		return values;
	};

	let property: 'user_group_roles' | 'role_in_group_ids';

	if (state.groupIds.length > 0) {
		property = 'role_in_group_ids';
	} else {
		property = 'user_group_roles';
	}

	filters[property] = {
		operator: FilterOperator.Includes,
		values: getRoleFilterValues(),
	};

	const {
		isLoading,
		records: users,
		pagination,
	} = useCollection<models.user.User>(endpoint, {
		showAllResults: true,
		queryParams: new URLSearchParams({
			filters: toFilterQuery(filters),
		}),
		defaultSortBy: 'name',
		defaultSortByOrder: 'asc',
	});

	const filteredUsers = users?.filter(
		(user: models.user.User) => !ignoredUserIds?.includes(user.id)
	);

	const userIds = state.users.map((user: models.user.User) => user.id);

	const filteredUserIds = filteredUsers.map(
		(user: models.user.User) => user.id
	);

	const hasUsers = filteredUsers?.length > 0;
	const canContiniouslyFetch = pagination.hasNext && !isLoading;

	const allSelected =
		arrays.intersects(userIds, filteredUserIds).length ===
		filteredUserIds.length;

	const handleToggleAll = () => {
		const uniqueMergedUsers = arrays.filterDuplicatesByKey<models.user.User>(
			[...state.users, ...filteredUsers],
			'id'
		);

		if (allSelected) {
			setState({ users: [] });
		} else {
			setState({
				users: uniqueMergedUsers,
			});
		}
	};

	const handleCancel = async () => {
		if (onCancel) {
			onCancel();
		}

		return true;
	};

	const handleSave = async () => {
		if (onSave) {
			if (state.users.length > 0) {
				onSave(state.users);
			}
		}

		return true;
	};

	const handleContiniousFetch = async (entry: IntersectionObserverEntry) => {
		if (entry.isIntersecting && canContiniouslyFetch) {
			await pagination.fetchNext();
		}
	};

	const emptyStateContent =
		state.groupIds.length === 1
			? t('All users in {group} have been added', { group: organization.name })
			: t('All users in the selected groups have been added');

	let emptyState: Table.EmptyStateProps = {
		image: (
			<Icon name="nav-members" size={1.6} className={css.emptyStateIcon} />
		),
		title: t('All users added'),
		content: emptyStateContent,
	};

	if (state.groupIds.length === 0) {
		emptyState = {
			image: (
				<Icon name="close-circle" size={2} className={css.emptyStateIcon} />
			),
			title: t('No filters'),
			content: t('Apply at least one filter'),
		};
	}

	const stateWithSetters = {
		...state,
		setSearch,
		setCurrentTab,
		setUsers,
		setGroupIds,
	};

	const Component = !isOpen ? null : (
		<UserSelectContext.Provider value={stateWithSetters}>
			<StepModal.Base onClose={close}>
				<StepModal.StepWithoutBody
					slug="user-select"
					title={title}
					prevLabel={cancelLabel}
					onPrev={handleCancel}
					canGoNext={state.users.length > 0}
					nextLabel={saveLabel}
					onNext={handleSave}
					closeOnNext>
					<ScrollSpy className={css.wrapper}>
						<UserSelectFilter defaultActiveTabId={defaultActiveTabId} />
						<Table.Table
							isLoading={isLoading}
							filtersApplied={!!state.search}
							emptyState={emptyState}
							columns={[
								{
									content: (
										<Input.Control
											standalone
											disabled={!hasUsers}
											checked={hasUsers && allSelected}
											type="checkbox"
										/>
									),
									width: 'max-content',
									onClick: handleToggleAll,
								},
								{ content: t('User') },
							]}>
							{hasUsers ? (
								<AvailableUsers
									users={filteredUsers}
									canContiniouslyFetch={canContiniouslyFetch}
									onContiniousFetch={handleContiniousFetch}
								/>
							) : null}
						</Table.Table>
					</ScrollSpy>
				</StepModal.StepWithoutBody>
				<StepModal.Step
					slug="group-select"
					title={t('Select groups')}
					nextLabel={t('Apply')}
					nextSlug="user-select"
					prevLabel={t('Cancel')}
					prevSlug="user-select"
					skipBody>
					<div style={{ flex: '1' }}>
						<AvailableGroups />
					</div>
				</StepModal.Step>
			</StepModal.Base>
		</UserSelectContext.Provider>
	);

	return {
		open,
		close,
		Component,
	};
}

interface UserSelectFilterProps {
	defaultActiveTabId: 'all' | 'user' | 'admin';
}

function UserSelectFilter({
	defaultActiveTabId,
}: UserSelectFilterProps): JSX.Element {
	const { goTo } = StepModal.useStepModalContext();
	const ctx = useUserSelectState();
	const inputRef = useRef<HTMLInputElement>();

	const tabs: Tab[] = [
		{ id: 'user', label: t('Players') },
		{ id: 'admin', label: t('Coaches') },
		{ id: 'all', label: t('All') },
	];

	const handleChange = (tab: Tab) => {
		if (tab) {
			ctx.setCurrentTab(tab.id as CurrentTab);
		}
	};

	const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
		ctx.setSearch(event.target.value);
	};

	const resetSearch = () => {
		ctx.setSearch('');

		if (inputRef.current) {
			inputRef.current.value = '';
		}
	};

	const showGroupSelect = () => goTo('group-select');

	useComponentWillUnmount(resetSearch);

	return (
		<div className={css.filterBar}>
			<Row columns="1fr auto" align="center" className={css.filterField}>
				<Input.Field
					ref={inputRef}
					placeholder={t('Search users')}
					onChange={handleSearch}
					changeDelay={350}>
					{ctx.search && (
						<Input.Suffix inline onClick={resetSearch}>
							<Icon name="close-circle-filled" />
						</Input.Suffix>
					)}
				</Input.Field>
				<FilterButton onClick={showGroupSelect} />
			</Row>
			<TabBar
				defaultActiveTabId={defaultActiveTabId}
				tabs={tabs}
				onChange={handleChange}
			/>
		</div>
	);
}

interface AvailableUsersProps {
	users: models.user.User[];
	canContiniouslyFetch: boolean;
	onContiniousFetch: (entry: IntersectionObserverEntry) => Promise<void>;
}

function AvailableUsers({
	users,
	canContiniouslyFetch,
	onContiniousFetch,
}: AvailableUsersProps): JSX.Element {
	const ctx = useUserSelectState();

	const selectedUserIds = ctx.users.map((user: models.user.User) => user.id);

	const handleSelect = (userId: number) => {
		const userExists =
			ctx.users.findIndex((user: models.user.User) => user.id === userId) !==
			-1;

		if (userExists) {
			ctx.setUsers(
				ctx.users.filter((user: models.user.User) => user.id !== userId)
			);
		} else {
			const user = users.find((user: models.user.User) => user.id === userId);
			ctx.setUsers([...ctx.users, user]);
		}
	};

	const itemSubTitle = (user: models.user.User): string => {
		const roles: string[] = arrays.unique<string>(
			user.groups?.map((membership: models.membership.Membership) =>
				models.membership.translatedRoleStringFromMembership(membership)
			)
		);

		const groups: string[] = user.groups?.map(
			(m: models.membership.Membership) => m.group?.name
		);

		if (ctx.groupIds.length <= 1) {
			return roles.join(', ');
		}

		return `${roles.join(', ')} – ${groups.join(', ')}`;
	};

	return (
		<Fragment>
			{users.map((user: models.user.User) => (
				<Table.Row key={user.id} onClick={() => handleSelect(user.id)}>
					<Table.Cell>
						<Input.Control
							standalone
							type="checkbox"
							value={user.id}
							onChange={handleSelect}
							checked={selectedUserIds.includes(user.id)}
						/>
					</Table.Cell>
					<Table.Cell>
						<Row columns="30px 1fr" align="center">
							<Avatar user={user} />
							<Column spacing={styles.spacing._2}>
								<span className={css.itemTitle}>
									{models.user.fullName(user)}
								</span>
								<span className={css.itemSubTitle}>{itemSubTitle(user)}</span>
							</Column>
						</Row>
					</Table.Cell>
				</Table.Row>
			))}
			{canContiniouslyFetch && (
				<Trigger
					key="continiouslyFetch"
					offsetY={-20}
					onTrigger={onContiniousFetch}
				/>
			)}
		</Fragment>
	);
}

function AvailableGroups(): JSX.Element {
	const ctx = useUserSelectState();
	const rootGroup = useCurrentOrganization();
	const { withNext } = StepModal.useStepModalContext();

	const [selected, setSelected] = useState<number[]>(ctx.groupIds);

	const handleSelect = (groupIds: number[]) => {
		setSelected(arrays.unique([...selected, ...groupIds]));
	};

	const handleDeselect = (groupIds: number[]) => {
		setSelected(arrays.remove(selected, groupIds));
	};

	withNext(async () => {
		ctx.setGroupIds(selected);

		return true;
	});

	return (
		<GroupTree
			rootGroupId={rootGroup.id}
			selected={selected}
			select={handleSelect}
			deselect={handleDeselect}
		/>
	);
}
