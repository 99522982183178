import { t } from '@transifex/native';

import { Flags } from 'pkg/models/group';

import * as json from 'pkg/json';
import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import { Country } from 'pkg/api/models/country';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { VideoSource } from 'pkg/api/models/video';

export enum Features {
	Advertisements = 'advertisements',
	AggregatedStatistics = 'aggregated-statistics',
	Assessments = 'assessments',
	Calendar = 'calendar',
	Chat = 'chat',
	CustomLinks = 'custom-links',
	Forms = 'forms',
	Imports = 'imports',
	IndividualDevelopment = 'individual-development',
	Matches = 'matches',
	Payments = 'payments',
	Posts = 'posts',
	ResourceManagement = 'resource-management',
	Scheduling = 'scheduling',
	TrainingLibrary = 'training-library',
	Video = 'video',
	Websites = 'websites',
	SingleSignOn = 'sso',
	DirectorySync = 'directory-sync',
	SplashScreen = 'splash-screen',
}

export enum MetaField {
	SplashScreenSettings = 'splash-screen-settings',
	PerformanceReviewTemplate = 'performance-review-template',
}

export interface MetaEntry {
	id?: number;
	key: MetaField;
	value: string;
}

export enum ExportUsersFields {
	FirstName = 'firstName',
	LastName = 'lastName',
	Gender = 'gender',
	Email = 'email',
	PhoneNumber = 'phoneNumber',
	Address = 'address',
	City = 'city',
	Region = 'region',
	PostalCode = 'postalCode',
	Nationality = 'nationality',
	PersonalId = 'personalId',
	DateOfBirth = 'dateOfBirth',
	ConnectedParents = 'connectedParents',
	ConnectedChild = 'connectedChild',
}

export enum PaymentProvider {
	Unset = 'unset',
	Dummy = 'dummy',
	Stripe = 'stripe',
	Purspot = 'purspot',
}

export interface ExportUsersPayload {
	firstName?: boolean;
	lastName?: boolean;
	gender?: boolean;
	email?: boolean;
	phoneNumber?: boolean;
	address?: boolean;
	city?: boolean;
	region?: boolean;
	postalCode?: boolean;
	nationality?: boolean;
	personalId?: boolean;
	dateOfBirth?: boolean;
	connectedParents?: boolean;
	connectedChild?: boolean;
}

export enum GroupStatusTypes {
	Unknown = 'unknown',
	Pending = 'pending',
	Active = 'active',
	Banned = 'banned',
	Inactive = 'inactive',
}

export interface Group extends Linkable, Dateable {
	id: number;
	parentGroupId?: number;
	organizationId: number;
	countryId?: number;
	sportId: number;
	externalId: string | number;
	allowInvites: boolean;
	name: string;
	slug: string;
	inviteCode: string;
	meta: MetaEntry[];
	flags: Flags[];
	features: Features[];
	videoSources: VideoSource[];
	paymentProviderActive: boolean;
	paymentProvider?: PaymentProvider;
	currency: string;
	seasonStartMonth: number;
	profileImageUrl: string;
	primaryColor: string;
	showRatingResults: boolean;
	order: number;
	serviceFeePercent: number;
	birthYearFrom?: number;
	birthYearTo?: number;
	sex?: number;

	// LOK integration fields
	lokOrganizationNumber?: string;
	lokSportId?: number;
	lokFromTime?: number;

	totalChildGroupCount?: number;
	totalPlayerCount?: number;
	totalAdminCount?: number;
	totalLegalGuardianCount?: number;
	totalPendingMembersCount?: number;

	identityProviders?: models.identityProvider.IdentityProvider[];
	organizationLinks?: models.organizationLink.OrganizationLink[];
	sport?: models.sport.Sport;
	children?: Group[];
	parents?: Group[];
	country?: Country;
	organization?: Group;
	userFields?: models.userFields.UserField[];
	depth?: number;
}

/**
 * This interface is used when getting organizations from the self endpoint
 */
export interface OrganizationData extends Group {
	unreadChatCount: number;
}

export function canShowChildren(group: Group) {
	return models.hasLink(group, 'show:children');
}

export function hasChildren(group: Group) {
	return group.children?.length > 0 || !!group.links?.['show:children'];
}

export function isOrganization(group: Group) {
	return group?.organizationId === null;
}

export function totalUserCount(group: Group) {
	return group.totalPlayerCount + group.totalAdminCount;
}

export function getOrganizationId(group: Group) {
	return isOrganization(group) ? group.id : group.organizationId;
}

export function isLOKActive(group: Group) {
	return !isOrganization(group) ? false : !!group.lokOrganizationNumber;
}

export function getPrimaryColor(group: Group) {
	const color = group?.primaryColor;

	if (color && color.indexOf(':') !== -1) {
		return color.split(':').pop();
	}

	return null;
}

export function getPrimaryColorValue(group: Group): string {
	let primaryColor = group?.primaryColor ?? '';

	if (primaryColor?.startsWith('hsl:')) {
		primaryColor = primaryColor.replace('hsl:', 'hsl(');
		primaryColor += ')';
	}

	return primaryColor;
}

export function getPrimaryColorStylesheetString(
	group: Group,
	opacity: number = 1
) {
	if (!group) return null;

	const color = getPrimaryColor(group);

	if (color) {
		return `hsla(${color}, ${opacity})`;
	}

	return null;
}

export function hasFlag(group: Group, flag: Flags): boolean {
	return group.flags.includes(flag);
}

export function hasFeature(group: Group, feature: Features): boolean {
	return group?.features?.includes(feature);
}

export function hasFeatures(group: Group, ...features: Features[]): boolean {
	return (
		features.length > 0 &&
		features.filter((feature: Features) => group.features.includes(feature))
			.length === features.length
	);
}

export function hasAnyFeature(group: Group, ...features: Features[]): boolean {
	return !!features.filter((feature: Features) =>
		group.features.includes(feature)
	).length;
}

export function hasActivePaymentProvider(group: Group): boolean {
	return (
		group.paymentProvider !== PaymentProvider.Unset &&
		group.paymentProviderActive &&
		group.currency != ''
	);
}

export function getMetaEntry(
	group: Group,
	metaField: MetaField
): Nullable<MetaEntry> {
	return (
		group.meta?.find((entry: MetaEntry) => entry.key === metaField) ?? null
	);
}

export function getMetaValue(
	group: Group,
	metaField: MetaField
): Nullable<string> {
	const entry = getMetaEntry(group, metaField);

	if (entry) {
		return entry?.value || null;
	}

	return null;
}

export function hasVideoSource(
	group: Group,
	videoSource: VideoSource
): boolean {
	return (
		hasFeature(group, Features.Video) &&
		group.videoSources.includes(videoSource)
	);
}

export function getCurrentSeason(group: Group) {
	if (!group) {
		return { start: 0, end: 0 };
	}

	const startMonth = group.seasonStartMonth;
	const now = new Date();

	let startYear = now.getUTCFullYear();
	if (now.getUTCMonth() + 1 < startMonth) {
		startYear -= 1;
	}

	const season = {
		start: Math.round(
			Date.UTC(startYear, startMonth - 1, 1, 0, 0, 0, 0) / 1000
		),
		end: Math.round(
			Date.UTC(startYear + 1, startMonth - 1, 1, 0, 0, 0, -1) / 1000
		),
	};

	return season;
}

export function getPerformanceReviewTemplate(group: Group): string {
	return getMetaValue(group, MetaField.PerformanceReviewTemplate) ?? '';
}

export const flattenGroups = (groups: Group[]) => {
	const flattenedGroups: Group[] = [];

	const flattenChildren = (group: Group) => {
		const index = flattenedGroups.findIndex((g) => g.id === group.id);

		if (group.children.length > 0) {
			flattenedGroups.splice(index + 1, 0, ...group.children);

			group.children.forEach((childGroup) => {
				flattenChildren(childGroup);
			});
		}
	};

	groups.forEach((g) => flattenChildren(g));

	return [...groups, ...flattenedGroups];
};

/**
 * @param startGroup Group that you want to build your tree from
 * @param childGroups Groups that should be looked at to belong in the tree
 * @param flatten If groups should come in a flat array or groups get a children key with groups
 *
 * @returns Group[]
 */
export function buildGroupTree(
	startGroup: Group,
	childGroups: Group[],
	flatten?: boolean
): Group[] {
	const findChildren = (group: Group) => {
		const children = childGroups
			.filter((g) => g.parentGroupId === group.id)
			.sort((a: Group, b: Group) => a.name.localeCompare(b.name));

		children.forEach((g) => {
			g.children = findChildren(g);
		});

		return children;
	};

	startGroup.children = findChildren(startGroup);

	childGroups.forEach((g) => {
		const children = findChildren(g);

		g.children = children;
	});

	const addDepth = (groups: Group[], depth: number = 0) => {
		const unsortedGroups = groups.some((group) => group.order === -1);
		groups
			.sort(
				unsortedGroups
					? (a, b) => a.name.localeCompare(b.name)
					: (a: Group, b: Group) => a.order - b.order
			)
			.forEach((group: Group) => {
				group.depth = depth;

				if (group.children) {
					return addDepth(group.children, depth + 1);
				}
			});
	};

	startGroup.depth = 0;
	addDepth(startGroup.children, 1);

	if (flatten) {
		const flattenedGroups: Group[] = [];

		const flattenChildren = (group: Group) => {
			const index = flattenedGroups.findIndex((g) => g.id === group.id);

			if (group.children.length > 0) {
				flattenedGroups.splice(index + 1, 0, ...group.children);

				group.children.forEach((childGroup) => {
					flattenChildren(childGroup);
				});
			}
		};

		flattenChildren(startGroup);

		return [startGroup, ...flattenedGroups];
	}

	return [startGroup];
}

export function getExportFields(country: Country) {
	let fields = [
		{ key: 'firstName', label: t(`First name`) },
		{ key: 'lastName', label: t(`Last name`) },
		{ key: 'gender', label: t(`Gender`) },
		{ key: 'email', label: t(`Email`) },
		{ key: 'phoneNumber', label: t(`Phone number`) },
		{ key: 'address', label: t(`Address`) },
		{ key: 'city', label: t(`City`) },
		{ key: 'region', label: t(`Region`) },
		{ key: 'postalCode', label: t(`Post code`) },
		{ key: 'nationality', label: t(`Nationality`) },
		{ key: 'personalId', label: t(`Personal ID number`) },
		{ key: 'dateOfBirth', label: t(`Date of birth`) },
		{
			key: 'connectedParents',
			label: t(`Parents`),
		},
		{ key: 'connectedChild', label: t(`Children`) },
	];

	if (country?.code !== 'SE' || !!!country?.code) {
		fields = fields.filter((i) => i.key !== 'personalId');
	}

	return fields;
}

export async function exportUsers(
	groupId: number,
	payload: ExportUsersPayload
): Promise<[boolean, string]> {
	return models.createExport(
		endpoints.Groups.ExportTeamMembers(groupId),
		payload
	);
}

function getTotalUnreadChats(organizations: OrganizationData[]) {
	return organizations.reduce((a, c) => a + c.unreadChatCount, 0);
}

function filterCurrentOrganization(
	organization: models.group.Group,
	organizations: OrganizationData[]
) {
	return organizations.filter((o) => o.id !== organization.id);
}

/**
 * Filters out provided org from a list of orgs and returns total unread chats
 * @param organization org you want to filter out
 * @param organizations list of orgs
 * @returns number of unread chats
 */
export function getUnreadChatsForOtherOrganizations(
	organization: models.group.Group,
	organizations: OrganizationData[]
) {
	return getTotalUnreadChats(
		filterCurrentOrganization(organization, organizations)
	);
}

/**
 *
 * @param groups that should be sorted by their order property
 */
export function sortByOrder(
	groups: models.group.Group[]
): models.group.Group[] {
	return groups.sort(
		(a: models.group.Group, b: models.group.Group) => a.order - b.order
	);
}

export function sortByName(groups: models.group.Group[]): models.group.Group[] {
	return groups.sort((a: models.group.Group, b: models.group.Group) =>
		a.name.localeCompare(b.name)
	);
}

interface SplashScreenSettings {
	enabled: boolean;
	imageUrl: string;
}

export function getSplashScreenSettings(
	group?: Group
): Nullable<SplashScreenSettings> {
	if (!group) return null;

	const meta = getMetaValue(group, MetaField.SplashScreenSettings);

	if (meta) {
		return json.parse<SplashScreenSettings>(meta);
	}

	return null;
}
