import { createContext, Fragment, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Options, RRule, rrulestr } from 'rrule';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';
import { setForceNavBarBackLink } from 'pkg/actions/app';

import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import { popState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import {
	getGmtString,
	getTimeZoneByGmtString,
	getTimeZoneFromSessionStorage,
	validateTimeZone,
} from 'pkg/timezone';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';
import {
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import {
	RSVPInterval,
	rsvpIntervalMappedToGranularity,
} from 'pkg/api/models/event';

import * as css from 'routes/event/single/styles.css';
import EventForm from 'routes/event/create/Form';
import { IFormState } from 'routes/event/interfaces';
import { validateRSVPDate } from 'routes/event/components/rsvp/form-inputs';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import * as Input from 'components/form/inputs';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import useAttachments from 'components/attachment/hooks/useAttachments';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';
import { useDialog } from 'design/dialog';

import { FormActionFooter } from 'styles/Form';

export const emptyFormState: IFormState = {
	groupId: null,
	title: '',
	location: '',
	description: '',
	type: '',
	startsAt: 0,
	endsAt: 0,
	sendInvites: null,
	selectedUsers: [],
	organizer: {},
	timezone: '',
	physicalStrainDefault: 50,
	onDay: true,
	rRuleString: '',
	isPrivate: false,
	isPublic: true,
	hideParticipants: false,
	meetBeforeMinutes: '0',
	rsvpBefore: false,
	rsvpIntervalCount: 1,
	rsvpInterval: RSVPInterval.RSVPIntervalDay,
	flags: [],
	match: {
		opponentName: '',
		startsAt: 0,
		duration: 90,
		location: '',
		matchCompetitionId: null,
		type: 'default',
		isHome: true,
	},
	errors: {
		title: false,
		location: false,
		type: false,
		timezone: false,
		kickOff: false,
		rsvpBefore: false,
	},
};

interface EventMixedState {
	users: models.user.User[];
}

export const emptyState: EventMixedState = {
	users: [],
};

interface EventFormContextProps {
	formState?: IFormState;
	setFormState?: MixedStateSetter<IFormState>;
}

export const EventFormContext = createContext<EventFormContextProps>({
	formState: emptyFormState,
	setFormState: () => {
		return;
	},
});

export default function EventCreateWrapper() {
	const qs = useQueryState();
	const eventType = (qs.get('eventType') as string) || '';
	const qsStartsAt = qs.get('startsAt');
	const qsEndsAt = qs.get('endsAt');

	const now = new Date();
	const defaultStartsAt = new DateTime(now)
		.setTime(now.getHours(), 0, 0, 0)
		.getUnixTimestamp();

	const currentMembership = useCurrentMembership();
	const groupId = currentMembership.groupId;
	const user = useCurrentUser();

	const startsAt = qsStartsAt
		? Number.parseInt(qsStartsAt as string, 10)
		: null;
	const endsAt = qsEndsAt ? Number.parseInt(qsEndsAt as string, 10) : null;
	const defaultStrain = eventType === 'match' ? 80 : 50;

	const initSelectedUsers = currentMembership.id ? [user] : [];
	const initOrganizer = currentMembership.id ? { [user.id]: true } : {};

	const timezone = useMemo(
		() => getGmtString(getTimeZoneFromSessionStorage()),
		[]
	);

	const initState: IFormState = {
		...emptyFormState,
		groupId,
		type: eventType,
		startsAt: startsAt || defaultStartsAt,
		endsAt: endsAt || defaultStartsAt + 60 * 60,
		selectedUsers: initSelectedUsers,
		organizer: initOrganizer,
		timezone: timezone || '',
		physicalStrainDefault: defaultStrain,
		match: {
			...emptyFormState.match,
			startsAt: startsAt || defaultStartsAt,
		},
	};

	const [formState, setFormState] = useMixedState<IFormState>(initState);

	return (
		<EventFormContext.Provider value={{ formState, setFormState }}>
			<EventCreate />
		</EventFormContext.Provider>
	);
}

const EventCreate = (): JSX.Element => {
	const EventContext = useContext(EventFormContext);
	const org = useCurrentOrganization();
	const now = new Date();
	const nowDt = new DateTime(now);
	const isFutureEvent =
		EventContext.formState.startsAt > nowDt.getUnixTimestamp();

	const dispatch = useDispatch();

	useComponentDidMount(() => {
		setForceNavBarBackLink(routes.Organization.Home(org.id))(dispatch);
	});

	const UseAttachments = useAttachments({});

	const [saving, setSaving] = useState(false);
	const { sendInvites } = EventContext.formState;

	const validate = () => {
		const errors = {
			title: false,
			location: false,
			type: false,
			timezone: false,
			kickOff: false,
			rsvpBefore: false,
		};

		if (!EventContext.formState.title) {
			errors.title = true;
		}

		if (!EventContext.formState.location) {
			errors.location = true;
		}

		if (!EventContext.formState.type) {
			errors.type = true;
		}

		if (EventContext.formState.rsvpBefore) {
			const rsvpTimestamp = DateTime.fromTimestamp(
				EventContext.formState.startsAt
			).prev(
				rsvpIntervalMappedToGranularity[EventContext.formState.rsvpInterval],
				EventContext.formState.rsvpIntervalCount
			);

			if (!validateRSVPDate(rsvpTimestamp.getUnixTimestamp())) {
				errors.rsvpBefore = true;
			}
		}

		if (
			(EventContext.formState.timezone.length > 0 &&
				!validateTimeZone(EventContext.formState.timezone)) ||
			EventContext.formState.timezone.length === 0
		) {
			errors.timezone = true;
		}

		const hasErrors = Object.values(errors).some((item) => item === true);

		if (!hasErrors) {
			EventContext.setFormState({ errors });
			return {};
		} else {
			EventContext.setFormState({ errors });
			return errors;
		}
	};

	const handleSetInvites = () => {
		EventContext.setFormState({
			sendInvites: !sendInvites,
		});
	};

	const handleSaveForm = async () => {
		setSaving(true);

		if (EventContext.formState.rRuleString !== '') {
			const rule = rrulestr(EventContext.formState.rRuleString);
			const startsAtDate = new Date(EventContext.formState.startsAt * 1000);
			const payloadRule: Partial<Options> = {
				dtstart: new Date(
					Date.UTC(
						startsAtDate.getFullYear(),
						startsAtDate.getMonth(),
						startsAtDate.getDate(),
						startsAtDate.getHours(),
						startsAtDate.getMinutes()
					)
				),
				tzid: getTimeZoneByGmtString(EventContext.formState.timezone).name,
				until: rule.options.until,
				interval: rule.options.interval,
				freq: rule.options.freq,
			};

			switch (rule.options.freq) {
				case RRule.WEEKLY:
					payloadRule.byweekday = rule.origOptions.byweekday;
					break;
				case RRule.MONTHLY:
					if (EventContext.formState.onDay) {
						payloadRule.bymonthday = rule.origOptions.bymonthday;
					} else {
						payloadRule.byweekday = rule.origOptions.byweekday;
					}
					break;
				case RRule.YEARLY:
					if (EventContext.formState.onDay) {
						payloadRule.bymonth = rule.origOptions.bymonth;
						payloadRule.bymonthday = rule.origOptions.bymonthday;
					} else {
						payloadRule.bymonth = rule.origOptions.bymonth;
						payloadRule.byweekday = rule.origOptions.byweekday;
					}
			}

			await actions.eventSeries.create(
				org.id,
				EventContext.formState,
				new RRule(payloadRule).toString(),
				UseAttachments.attachments
			);
		} else {
			await actions.events.createEvent(
				org.id,
				{
					groupId: EventContext.formState.groupId,
					startsAt: EventContext.formState.startsAt,
					endsAt: EventContext.formState.endsAt,
					title: EventContext.formState.title,
					location: EventContext.formState.location,
					timezone: EventContext.formState.timezone,
					description: EventContext.formState.description,
					physicalStrainDefault: EventContext.formState.physicalStrainDefault,
					type: EventContext.formState.type,
					isPrivate: EventContext.formState.isPrivate,
					isPublic: EventContext.formState.isPublic,
					hideParticipants: EventContext.formState.hideParticipants,
					meetBeforeMinutes: Number.parseInt(
						EventContext.formState.meetBeforeMinutes,
						10
					),
					match: EventContext.formState.match,
					selectedUsers: EventContext.formState.selectedUsers,
					organizer: EventContext.formState.organizer,
					sendInvites: EventContext.formState.sendInvites,
					rsvpBefore: EventContext.formState.rsvpBefore,
					rsvpInterval: EventContext.formState.rsvpInterval,
					rsvpIntervalCount: EventContext.formState.rsvpIntervalCount,
					flags: EventContext.formState.flags,
				},
				UseAttachments.attachments as models.attachment.Attachment[]
			);

			setSaving(false);
		}

		return true;
	};

	const handleCancel = () => popState();

	const validateBeforeSave = async () => {
		const errors: {
			title?: string;
			location?: string;
			type?: string;
			timeZone?: string;
			rsvpBefore?: string;
		} = validate();

		if (Object.values(errors).length > 0) {
			const errorMessages = [];

			if (errors.title) {
				errorMessages.push(t('Event title'));
			}

			if (errors.location) {
				errorMessages.push(t('Location'));
			}

			if (errors.type) {
				errorMessages.push(t('Event type'));
			}

			if (errors.timeZone) {
				errorMessages.push(t('Time zone'));
			}

			if (errors.rsvpBefore) {
				flashActions.show({
					title: t(`RSVP date can't be set before current time`),
					message: t('Please set a later RSVP date'),
				});
			} else {
				flashActions.show({
					title: t('Mandatory fields missing'),
					message: errorMessages.join(', '),
				});
			}

			setSaving(false);
			return false;
		} else if (
			EventContext.formState.selectedUsers.length > 0 &&
			isFutureEvent
		) {
			confirmSendInvites();
		} else {
			handleSaveForm();
		}
	};

	const confirmSendInvites = useDialog(
		{
			wide: true,
			symbol: 'calendar_add_on',
			title: t('Send invites?'),
			confirmLabel: t('Finish'),
			onConfirm: handleSaveForm,
			onCancel: () => {
				EventContext.setFormState({ sendInvites: true });
			},
			message: (
				<Column>
					<span>
						{t(
							'If you send invites, participants will be notified to take action. Otherwise invited users will automatically be added as "Going".'
						)}
					</span>
					<label className={css.sendInvites} onClick={handleSetInvites}>
						<Row columns="auto 1fr" align="center" spacing={styles.spacing._3}>
							<Input.Control standalone type="checkbox" checked={sendInvites} />
							<strong>{t('Yes, send invites')}</strong>
						</Row>
					</label>
				</Column>
			),
		},
		[EventContext.formState]
	);

	const isSmallScreen = useSmallScreen();

	return (
		<Fragment>
			<ActionBar.SaveBar maxWidth={PageWidths.WIDE} smallScreenFullWidth>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					onClick={handleCancel}>
					{t('Cancel')}
				</Button>
				<Button
					primary
					onClick={validateBeforeSave}
					block={isSmallScreen}
					large={isSmallScreen}
					isLoading={saving}
					testid="events.form.save">
					{t('Save')}
				</Button>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner
					maxWidth={PageWidths.WIDE}
					columns={3}
					columnSpacing={styles.spacing._9}
					columnSizes="1fr 1px 0.6fr">
					<EventForm useAttachments={UseAttachments} />
				</LargeScreenContent.Inner>
				<LargeScreenContent.Inner maxWidth={PageWidths.WIDE} topSpacing={0}>
					<FormActionFooter>
						<Button onClick={handleCancel}>{t('Cancel')}</Button>
						<Button primary onClick={validateBeforeSave} isLoading={saving}>
							{t('Save')}
						</Button>
					</FormActionFooter>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<EventForm useAttachments={UseAttachments} />
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
};
