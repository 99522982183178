import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import {
	preferences,
	saveCircle,
	createCircle,
	saveRectangle,
	createRectangle,
	createIcon,
	saveIcon,
	saveFreehand,
	createFreehand,
	saveText,
	createText,
	savePlayer,
	createPlayer,
	saveMultipoint,
	createMultipoint,
	createSquiggly,
	saveSquiggly,
	createJersey,
	saveJersey,
} from 'components/drawing/config/preferences';

const _context = 'training_library/drawing';

// artboard config
export const DEFAULT_ZOOM = 0.95;
export const MIN_ZOOM = 0.5;
export const MAX_ZOOM = 4;
export const DEFAULT_ZOOM_OFFSET = 1 - DEFAULT_ZOOM;
export const DRAWING_DATA_VERSION = 1;

// type definitions
interface BaseTool {
	id: string;
	tool: string;
	top: number;
	left: number;
	scaleX: number;
	scaleY: number;
	fill: string;
	stroke: string;
	strokeDashArray: number[];
}

export interface Circle extends BaseTool {
	ry: number;
	rx: number;
}

export interface Rectangle extends BaseTool {
	width: number;
	height: number;
}

export type Tools = Circle | Rectangle;

interface Shape {
	icon: string;
	prefs: string[];
	key?: string;
	multipoint?: boolean;
	autoDone?: boolean;
	create: (object: unknown) => void;
	save: (object: unknown) => void;
}

interface Icon {
	url: string;
	prefs?: string[];
	key?: string;
	width?: number;
	create: (object: unknown) => void;
	save: (object: unknown) => void;
	label: string;
}

interface ToolConfig {
	player: {
		colors: string[];
		prefs: string[];
		create: (object: unknown) => void;
		save: (object: unknown) => void;
	};
	jersey: {
		colors: string[];
		prefs: string[];
		create: (object: unknown) => void;
		save: (object: unknown) => void;
	};
	shapes: {
		[key: string]: Shape;
	};
	icons: {
		[key: string]: Icon;
	};
}

// tool config
export const toolConfig: ToolConfig = {
	shapes: {
		circle: {
			icon: 'drawing-circle',
			prefs: ['fillColor', 'strokeColor', 'strokeWidth', 'strokeDash'],
			key: '1',
			create: createCircle,
			save: saveCircle,
		},
		circle_dashed: {
			icon: 'drawing-circle-dashed',
			prefs: ['fillColor', 'strokeColor', 'strokeWidth', 'strokeDash'],
			key: '2',
			create: createCircle,
			save: saveCircle,
		},
		rectangle: {
			icon: 'drawing-rectangle',
			prefs: ['fillColor', 'strokeColor', 'strokeWidth', 'strokeDash'],
			key: '3',
			create: createRectangle,
			save: saveRectangle,
		},
		rectangle_dashed: {
			icon: 'drawing-rectangle-dashed',
			prefs: ['fillColor', 'strokeColor', 'strokeWidth', 'strokeDash'],
			key: '4',
			create: createRectangle,
			save: saveRectangle,
		},
		polygon: {
			icon: 'drawing-polygon',
			multipoint: true,
			prefs: [
				'fillColor',
				'strokeAndFillColorSolid',
				'strokeWidth',
				'strokeDash',
			],
			key: '5',
			create: createMultipoint,
			save: saveMultipoint,
		},
		polygon_dashed: {
			icon: 'drawing-polygon-dashed',
			multipoint: true,
			prefs: [
				'fillColor',
				'strokeAndFillColorSolid',
				'strokeWidth',
				'strokeDash',
			],
			key: '6',
			create: createMultipoint,
			save: saveMultipoint,
		},
		line: {
			icon: 'drawing-line',
			multipoint: true,
			prefs: ['strokeAndFillColor', 'strokeWidth', 'strokeDash'],
			key: '7',
			create: createMultipoint,
			save: saveMultipoint,
		},
		line_dashed: {
			icon: 'drawing-line-dashed',
			multipoint: true,
			prefs: ['strokeAndFillColor', 'strokeWidth', 'strokeDash'],
			key: '8',
			create: createMultipoint,
			save: saveMultipoint,
		},
		arrow: {
			icon: 'drawing-arrow',
			multipoint: true,
			prefs: [
				'strokeAndFillColorSolid',
				'strokeWidth',
				'strokeDash',
				'doubleArrow',
			],
			key: '9',
			create: createMultipoint,
			save: saveMultipoint,
		},
		arrow_dashed: {
			icon: 'drawing-arrow-dashed',
			multipoint: true,
			prefs: [
				'strokeAndFillColorSolid',
				'strokeWidth',
				'strokeDash',
				'doubleArrow',
			],
			key: '0',
			create: createMultipoint,
			save: saveMultipoint,
		},
		line_squiggly: {
			icon: 'drawing-squiggly-line',
			multipoint: true,
			key: 'S',
			prefs: ['strokeAndFillColorSolid', 'strokeWidth'],
			create: createSquiggly,
			save: saveSquiggly,
		},
		arrow_squiggly: {
			icon: 'drawing-squiggly-arrow',
			multipoint: true,
			key: 'D',
			prefs: ['strokeAndFillColorSolid', 'strokeWidth'],
			create: createSquiggly,
			save: saveSquiggly,
		},

		freehand: {
			icon: 'drawing-freehand',
			prefs: ['strokeColorMulti', 'strokeWidthMulti'],
			key: 'F',
			create: createFreehand,
			save: saveFreehand,
		},
		text: {
			icon: 'drawing-text',
			prefs: ['backgroundColor', 'textColor', 'textLabel'],
			key: 'T',
			create: createText,
			save: saveText,
			autoDone: true,
		},
	},
	icons: {
		fotball: {
			url: 'img/drawing/icons/football/football.svg',
			width: 30,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		fotballPile: {
			url: 'img/drawing/icons/football/football_pile.svg',
			width: 45,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		goalLarge: {
			url: 'img/drawing/icons/football/goal_large.svg',
			prefs: ['equipmentFill'],
			width: 120,
			create: createIcon,
			save: saveIcon,
			label: t('Goal (large)', { _context }),
		},
		goalSmall: {
			url: 'img/drawing/icons/football/goal_small.svg',
			prefs: ['equipmentFill'],
			width: 50,
			create: createIcon,
			save: saveIcon,
			label: t('Goal (small)', { _context }),
		},
		pole: {
			url: 'img/drawing/icons/football/pole.svg',
			prefs: ['equipmentFill'],
			width: 55,
			create: createIcon,
			save: saveIcon,
			label: t('Pole', { _context }),
		},
		cone: {
			url: 'img/drawing/icons/football/cone.svg',
			prefs: ['equipmentFill'],
			width: 35,
			create: createIcon,
			save: saveIcon,
			label: t('Cone', { _context }),
		},
		smallCone: {
			url: 'img/drawing/icons/football/small_cone.svg',
			prefs: ['equipmentFill'],
			width: 25,
			create: createIcon,
			save: saveIcon,
			label: t('Cone (small)', { _context }),
		},
		flatCone: {
			url: 'img/drawing/icons/football/flat_cone.svg',
			prefs: ['equipmentFill'],
			width: 18,
			create: createIcon,
			save: saveIcon,
			label: t('Cone (flat)', { _context }),
		},
		bracket: {
			url: 'img/drawing/icons/football/bracket.svg',
			prefs: ['equipmentFill'],
			width: 32,
			create: createIcon,
			save: saveIcon,
			label: t('Bracket', { _context }),
		},
		fence: {
			url: 'img/drawing/icons/football/fence.svg',
			prefs: ['equipmentFill'],
			width: 70,
			create: createIcon,
			save: saveIcon,
			label: t('Fence', { _context }),
		},
		mannequins: {
			url: 'img/drawing/icons/football/mannequins.svg',
			prefs: ['equipmentFill'],
			width: 70,
			create: createIcon,
			save: saveIcon,
			label: t('Mannequins', { _context }),
		},
		mannequin: {
			url: 'img/drawing/icons/football/mannequin.svg',
			prefs: ['equipmentFill'],
			width: 70,
			create: createIcon,
			save: saveIcon,
			label: t('Mannequin', { _context }),
		},
		tennisBall: {
			url: 'img/drawing/icons/tennis/ball.svg',
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		tennisBallRed: {
			url: 'img/drawing/icons/tennis/red_ball.svg',
			prefs: ['equipmentFill'],
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		tennisBallOrange: {
			url: 'img/drawing/icons/tennis/orange_ball.svg',
			prefs: ['equipmentFill'],
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		tennisBallGreen: {
			url: 'img/drawing/icons/tennis/green_ball.svg',
			prefs: ['equipmentFill'],
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		tennisBalls: {
			url: 'img/drawing/icons/tennis/balls.svg',
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		tennisCart: {
			url: 'img/drawing/icons/tennis/cart.svg',
			prefs: ['equipmentFill'],
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Cart', { _context }),
		},
		tennisCartBalls: {
			url: 'img/drawing/icons/tennis/cart_balls.svg',
			prefs: ['equipmentFill'],
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Cart', { _context }),
		},
		tennisNet: {
			url: 'img/drawing/icons/tennis/net.svg',
			prefs: ['equipmentFill'],
			width: 110,
			create: createIcon,
			save: saveIcon,
			label: t('Net', { _context }),
		},
		tennisRacket: {
			url: 'img/drawing/icons/tennis/racket.svg',
			width: 30,
			prefs: ['equipmentFill'],
			create: createIcon,
			save: saveIcon,
			label: t('Racket', { _context }),
		},
		tennisPlayerStraight: {
			url: 'img/drawing/icons/tennis/player_straight.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayerWaiting: {
			url: 'img/drawing/icons/tennis/player_waiting.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayerRight: {
			url: 'img/drawing/icons/tennis/player_right.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayerLeft: {
			url: 'img/drawing/icons/tennis/player_left.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer01: {
			url: 'img/drawing/icons/tennis/player_01.svg',
			prefs: ['equipmentFill'],
			width: 55,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer02: {
			url: 'img/drawing/icons/tennis/player_02.svg',
			prefs: ['equipmentFill'],
			width: 55,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer03: {
			url: 'img/drawing/icons/tennis/player_03.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer04: {
			url: 'img/drawing/icons/tennis/player_04.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer05: {
			url: 'img/drawing/icons/tennis/player_05.svg',
			prefs: ['equipmentFill'],
			width: 51,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer06: {
			url: 'img/drawing/icons/tennis/player_06.svg',
			prefs: ['equipmentFill'],
			width: 51,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer07: {
			url: 'img/drawing/icons/tennis/player_07.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer08: {
			url: 'img/drawing/icons/tennis/player_08.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer09: {
			url: 'img/drawing/icons/tennis/player_09.svg',
			prefs: ['equipmentFill'],
			width: 71,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer10: {
			url: 'img/drawing/icons/tennis/player_10.svg',
			prefs: ['equipmentFill'],
			width: 71,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer11: {
			url: 'img/drawing/icons/tennis/player_11.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer12: {
			url: 'img/drawing/icons/tennis/player_12.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer13: {
			url: 'img/drawing/icons/tennis/player_13.svg',
			prefs: ['equipmentFill'],
			width: 57,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer14: {
			url: 'img/drawing/icons/tennis/player_14.svg',
			prefs: ['equipmentFill'],
			width: 47,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer15: {
			url: 'img/drawing/icons/tennis/player_15.svg',
			prefs: ['equipmentFill'],
			width: 47,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer16: {
			url: 'img/drawing/icons/tennis/player_16.svg',
			prefs: ['equipmentFill'],
			width: 75,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer17: {
			url: 'img/drawing/icons/tennis/player_17.svg',
			prefs: ['equipmentFill'],
			width: 75,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer18: {
			url: 'img/drawing/icons/tennis/player_18.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		tennisPlayer19: {
			url: 'img/drawing/icons/tennis/player_19.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelBall: {
			url: 'img/drawing/icons/padel/ball.svg',
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		padelBallRed: {
			url: 'img/drawing/icons/padel/red_ball.svg',
			prefs: ['equipmentFill'],
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		padelBallOrange: {
			url: 'img/drawing/icons/padel/orange_ball.svg',
			prefs: ['equipmentFill'],
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		padelBallGreen: {
			url: 'img/drawing/icons/padel/green_ball.svg',
			prefs: ['equipmentFill'],
			width: 15,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		padelBalls: {
			url: 'img/drawing/icons/padel/balls.svg',
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		padelCart: {
			url: 'img/drawing/icons/padel/cart.svg',
			prefs: ['equipmentFill'],
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Cart', { _context }),
		},
		padelCartBalls: {
			url: 'img/drawing/icons/padel/cart_balls.svg',
			prefs: ['equipmentFill'],
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Cart', { _context }),
		},
		padelNet: {
			url: 'img/drawing/icons/padel/net.svg',
			prefs: ['equipmentFill'],
			width: 110,
			create: createIcon,
			save: saveIcon,
			label: t('Net', { _context }),
		},
		padelRacket: {
			url: 'img/drawing/icons/padel/racket.svg',
			width: 30,
			prefs: ['equipmentFill'],
			create: createIcon,
			save: saveIcon,
			label: t('Racket', { _context }),
		},
		padelPlayerStraight: {
			url: 'img/drawing/icons/padel/player_straight.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayerWaiting: {
			url: 'img/drawing/icons/padel/player_waiting.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayerRight: {
			url: 'img/drawing/icons/padel/player_right.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayerLeft: {
			url: 'img/drawing/icons/padel/player_left.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelVolleyRight: {
			url: 'img/drawing/icons/padel/player_volley_right.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelVolleyLeft: {
			url: 'img/drawing/icons/padel/player_volley_left.svg',
			prefs: ['equipmentFill'],
			width: 72,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer01: {
			url: 'img/drawing/icons/padel/player_01.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer02: {
			url: 'img/drawing/icons/padel/player_02.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer03: {
			url: 'img/drawing/icons/padel/player_03.svg',
			prefs: ['equipmentFill'],
			width: 90,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer04: {
			url: 'img/drawing/icons/padel/player_04.svg',
			prefs: ['equipmentFill'],
			width: 90,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer05: {
			url: 'img/drawing/icons/padel/player_05.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer06: {
			url: 'img/drawing/icons/padel/player_06.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer07: {
			url: 'img/drawing/icons/padel/player_07.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer08: {
			url: 'img/drawing/icons/padel/player_08.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer09: {
			url: 'img/drawing/icons/padel/player_09.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer10: {
			url: 'img/drawing/icons/padel/player_10.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer11: {
			url: 'img/drawing/icons/padel/player_11.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer12: {
			url: 'img/drawing/icons/padel/player_12.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer13: {
			url: 'img/drawing/icons/padel/player_13.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer14: {
			url: 'img/drawing/icons/padel/player_14.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer15: {
			url: 'img/drawing/icons/padel/player_15.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer16: {
			url: 'img/drawing/icons/padel/player_16.svg',
			prefs: ['equipmentFill'],
			width: 65,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer17: {
			url: 'img/drawing/icons/padel/player_17.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer18: {
			url: 'img/drawing/icons/padel/player_18.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer19: {
			url: 'img/drawing/icons/padel/player_19.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer20: {
			url: 'img/drawing/icons/padel/player_20.svg',
			prefs: ['equipmentFill'],
			width: 100,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer21: {
			url: 'img/drawing/icons/padel/player_21.svg',
			prefs: ['equipmentFill'],
			width: 70,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		padelPlayer22: {
			url: 'img/drawing/icons/padel/player_22.svg',
			prefs: ['equipmentFill'],
			width: 70,
			create: createIcon,
			save: saveIcon,
			label: t('Player', { _context }),
		},
		handball: {
			url: 'img/drawing/icons/handball/ball.svg',
			width: 18,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		handballs: {
			url: 'img/drawing/icons/handball/balls.svg',
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		basketball: {
			url: 'img/drawing/icons/basketball/ball.svg',
			width: 20,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		basketballs: {
			url: 'img/drawing/icons/basketball/balls.svg',
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		basketGoal: {
			url: 'img/drawing/icons/basketball/goal.svg',
			prefs: ['equipmentFill'],
			width: 75,
			create: createIcon,
			save: saveIcon,
			label: t('Basket hoop', { _context }),
		},
		volleyball: {
			url: 'img/drawing/icons/volleyball/ball.svg',
			width: 30,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		volleyballs: {
			url: 'img/drawing/icons/volleyball/balls.svg',
			width: 50,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		volleyballGrayscale: {
			url: 'img/drawing/icons/volleyball/ball_grayscale.svg',
			width: 30,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		volleyballsGrayscale: {
			url: 'img/drawing/icons/volleyball/balls_grayscale.svg',
			width: 50,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		volleyNet: {
			url: 'img/drawing/icons/volleyball/net.svg',
			prefs: ['equipmentFill'],
			width: 110,
			create: createIcon,
			save: saveIcon,
			label: t('Net', { _context }),
		},
		fieldHockeyBall: {
			url: 'img/drawing/icons/field_hockey/ball.svg',
			width: 25,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		fieldHockeyBalls: {
			url: 'img/drawing/icons/field_hockey/balls.svg',
			width: 45,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		fieldHockeyBallYellow: {
			url: 'img/drawing/icons/field_hockey/ballyellow.svg',
			width: 25,
			create: createIcon,
			save: saveIcon,
			label: t('Ball', { _context }),
		},
		fieldHockeyBallsYellow: {
			url: 'img/drawing/icons/field_hockey/ballsyellow.svg',
			width: 45,
			create: createIcon,
			save: saveIcon,
			label: t('Balls', { _context }),
		},
		fieldHockeyStickLeft: {
			url: 'img/drawing/icons/field_hockey/stickleft.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Stick left', { _context }),
		},
		fieldHockeyStickRight: {
			url: 'img/drawing/icons/field_hockey/stickright.svg',
			prefs: ['equipmentFill'],
			width: 80,
			create: createIcon,
			save: saveIcon,
			label: t('Stick right', { _context }),
		},
		iceHockeyPuck: {
			url: 'img/drawing/icons/ice_hockey/icehockeypuck.svg',
			width: 7,
			create: createIcon,
			save: saveIcon,
			label: t('Puck', { _context }),
		},
		iceHockeyPucks: {
			url: 'img/drawing/icons/ice_hockey/icehockeypucks.svg',
			width: 40,
			create: createIcon,
			save: saveIcon,
			label: t('Pucks', { _context }),
		},
		iceHockeyStickLeft: {
			url: 'img/drawing/icons/ice_hockey/icehockeystickleft.svg',
			width: 35,
			create: createIcon,
			save: saveIcon,
			label: t('Stick left', { _context }),
		},
		iceHockeyStickRight: {
			url: 'img/drawing/icons/ice_hockey/icehockeystickright.svg',
			width: 35,
			create: createIcon,
			save: saveIcon,
			label: t('Stick right', { _context }),
		},
		iceHockeyGoal: {
			url: 'img/drawing/icons/ice_hockey/icehockeygoal.svg',
			width: 50,
			create: createIcon,
			save: saveIcon,
			label: t('Goal', { _context }),
		},
		iceHockeyStickHandling: {
			url: 'img/drawing/icons/ice_hockey/icehockeystickhandling.svg',
			width: 30,
			create: createIcon,
			save: saveIcon,
			label: t('Stickhandling', { _context }),
		},
		iceHockeyTire: {
			url: 'img/drawing/icons/ice_hockey/icehockeytire.svg',
			width: 25,
			create: createIcon,
			save: saveIcon,
			label: t('Tire', { _context }),
		},
		iceHockeyTriangle: {
			url: 'img/drawing/icons/ice_hockey/icehockeytriangle.svg',
			width: 25,
			create: createIcon,
			save: saveIcon,
			label: t('Triangle', { _context }),
		},
		iceHockeyOpponent: {
			url: 'img/drawing/icons/ice_hockey/opponent.svg',
			prefs: ['equipmentFill'],
			width: 25,
			create: createIcon,
			save: saveIcon,
			label: t('Opponent', { _context }),
		},
	},
	player: {
		colors: [
			styles.palette.orange[500],
			styles.palette.yellow[500],
			styles.palette.green[400],
			styles.palette.red[500],
			styles.palette.blue[500],
			styles.palette.purple[500],
			styles.palette.gray[900],
			styles.palette.gray[500],
		],
		prefs: ['playerColor', 'playerText', 'playerDirection'],
		save: savePlayer,
		create: createPlayer,
	},
	jersey: {
		colors: [
			styles.palette.orange[500],
			styles.palette.yellow[500],
			styles.palette.green[400],
			styles.palette.red[500],
			styles.palette.blue[500],
			styles.palette.purple[500],
			styles.palette.gray[900],
			styles.palette.gray[500],
		],
		prefs: ['fillColor', 'playerText', 'playerTextSecondary'],
		save: saveJersey,
		create: createJersey,
	},
};

// config helpers
export const getConfig = (object: any): any => {
	const config =
		object.tool &&
		(toolConfig.shapes[object.tool] ||
			(object.tool === 'player' && toolConfig.player) ||
			(object.tool === 'jersey' && toolConfig.jersey) ||
			toolConfig.icons[object.tool.substring(5)]);

	return config || {};
};

export const getPrefs = (object: any) => {
	const config = getConfig(object);

	// return preference object instead of preference name
	return config?.prefs?.map((name: string) => preferences[name]);
};
