import { t } from '@transifex/native';

import { FilterOperator } from 'pkg/filters';
import { FilterGroups } from 'pkg/filters/use_filters';
import { UserProductStatuses } from 'pkg/api/models/user_product';
import { UserSex } from 'pkg/api/models/user';

import ProductsSelectModal from 'routes/payments/products/modals/select';

import SelectFormModal from 'components/forms/select_modal';
import { GroupSelectModal } from 'components/group/select_modal';

type ConfigTypes =
	| 'groups'
	| 'group_role'
	| 'sex'
	| 'inactivated'
	| 'products'
	| 'product_status'
	| 'product_valid'
	| 'product_created'
	| 'account'
	| 'parent_connection'
	| 'child_connection'
	| 'registrations'
	| 'lok_data'
	| 'user_created'
	| 'archived'
	| 'birth_date'
	| 'required_information';

export const usersFilterConfig = (): {
	[key in ConfigTypes]: FilterGroups;
} => ({
	birth_date: {
		[t('User born')]: {
			filters: {
				['User born']: {
					type: 'date',
					property: 'birth_date',
					settings: {
						dateFilterOperators: ['between'],
					},
				},
			},
		},
	},
	sex: {
		[t('Sex', { _context: 'gender' })]: {
			filters: {
				['Sex']: {
					type: 'checkbox',
					property: 'sex',
					operator: FilterOperator.Includes,
					values: {
						[t('Unknown', { _context: 'unknown gender' })]: UserSex.Unknown,
						[t('Male', { _context: 'male gender' })]: UserSex.Male,
						[t('Female', { _context: 'female gender' })]: UserSex.Female,
						[t('Not applicable', { _context: 'not applicable gender' })]:
							UserSex.NotApplicable,
					},
				},
			},
		},
	},
	user_created: {
		[t('User created')]: {
			filters: {
				['User created']: {
					type: 'date',
					property: 'user_created',
				},
			},
		},
	},
	lok_data: {
		[t('LOK')]: {
			filters: {
				['LOK']: {
					type: 'checkbox',
					property: 'lok_requires_additional_data',
					operator: FilterOperator.Equals,
					values: {
						[t('Show contacts invalid for LOK')]: 'true',
					},
				},
			},
		},
	},
	parent_connection: {
		[t('Parent connection')]: {
			filters: {
				['Parent connection']: {
					type: 'radio',
					property: 'parent_connection',
					operator: FilterOperator.Equals,
					values: {
						[t('Have parents connected')]: 'true',
						[t(`Doesn't have connected parent`)]: 'false',
					},
				},
			},
		},
	},
	child_connection: {
		[t('Child connection')]: {
			filters: {
				['Child connection']: {
					type: 'radio',
					property: 'child_connection',
					operator: FilterOperator.Equals,
					values: {
						[t('Have child connected')]: 'true',
						[t(`Doesn't have connected child`)]: 'false',
					},
				},
			},
		},
	},
	account: {
		[t('Account')]: {
			filters: {
				Account: {
					type: 'radio',
					property: 'accountId',
					operator: FilterOperator.Equals,
					values: {
						[t('Has account')]: 'true',
						[t(`Doesn't have account`)]: 'false',
					},
				},
			},
		},
	},
	group_role: {
		[t('Group role')]: {
			filters: {
				['Group role']: {
					type: 'groupRole',
					property: 'role_in_group_ids',
					operator: FilterOperator.Includes,
					values: {
						[t('Admin')]: 'admin',
						[t('Staff')]: 'staff',
						[t('Player')]: 'user',
						[t('Parent')]: 'parent',
					},
				},
			},
		},
	},
	groups: {
		[t('Groups')]: {
			filters: {
				[t('Is in group')]: {
					type: 'modal',
					property: 'group_ids',
					operator: FilterOperator.Includes,
					settings: {
						modal: <GroupSelectModal />,
						buttonLabel: t('Select groups'),
						hideButton: true,
					},
				},
				[t('Is not in group')]: {
					type: 'modal',
					property: 'group_ids',
					operator: FilterOperator.Excludes,
					settings: {
						modal: <GroupSelectModal />,
						buttonLabel: t('Select groups'),
						hideButton: true,
					},
				},
				['No Groups']: {
					type: 'checkbox',
					property: 'user_group_roles',
					operator: FilterOperator.Excludes,
					settings: {
						transformValuesToArray: true,
					},
					values: {
						[t('No Group')]: 'admin,staff,user',
					},
				},
			},
		},
	},
	products: {
		[t('Products')]: {
			filters: {
				[t('Has product')]: {
					type: 'modal',
					property: 'product_ids',
					operator: FilterOperator.Includes,
					settings: {
						modal: <ProductsSelectModal />,
						buttonLabel: t('Select products'),
						hideButton: true,
					},
				},
				[t(`Doesn't have product`)]: {
					type: 'modal',
					property: 'product_ids',
					operator: FilterOperator.Excludes,
					settings: {
						modal: <ProductsSelectModal />,
						buttonLabel: t('Select products'),
						hideButton: true,
					},
				},
			},
		},
	},
	registrations: {
		[t('Registrations')]: {
			filters: {
				[t('Have registrations')]: {
					type: 'modal',
					property: 'form_ids',
					operator: FilterOperator.Includes,
					settings: {
						modal: <SelectFormModal />,
						buttonLabel: t('Select registrations'),
						hideButton: true,
					},
				},
				[t(`Doesn't have registrations`)]: {
					type: 'modal',
					property: 'form_ids',
					operator: FilterOperator.Excludes,
					settings: {
						modal: <SelectFormModal />,
						buttonLabel: t('Select registrations'),
						hideButton: true,
					},
				},
			},
		},
	},
	product_created: {
		[t('Product assigned')]: {
			filters: {
				['Product assigned']: {
					type: 'date',
					property: 'product_created',
					operator: FilterOperator.Between,
				},
			},
		},
	},
	product_valid: {
		[t('Product valid')]: {
			filters: {
				['Valid products']: {
					type: 'date',
					property: 'product_valid',
					operator: FilterOperator.Between,
				},
			},
		},
	},
	product_status: {
		[t('Status')]: {
			filters: {
				['Product status']: {
					type: 'checkbox',
					property: 'product_status',
					operator: FilterOperator.Includes,
					values: {
						[t('Active')]: UserProductStatuses.Active,
						[t('Past due')]: UserProductStatuses.PastDue,
						[t('Expired')]: UserProductStatuses.Expired,
						[t('Open')]: UserProductStatuses.Open,
					},
				},
			},
		},
	},
	archived: {
		[t('Archived')]: {
			filters: {
				['Archived']: {
					type: 'checkbox',
					property: 'archived',
					operator: FilterOperator.Set,
					values: {
						[t('Show archived')]: 'archived',
					},
				},
			},
		},
	},
	required_information: {
		[t('Missing information')]: {
			filters: {
				['Missing information']: {
					type: 'checkbox',
					property: 'required_information',
					operator: FilterOperator.Equals,
					values: {
						[t('Missing required information')]: 'true',
					},
				},
			},
		},
	},
	inactivated: {
		[t('Removed from group')]: {
			filters: {
				['Removed from group']: {
					type: 'date',
					property: 'inactivated',
					operator: FilterOperator.Between,
				},
			},
		},
	},
});

export function getFilters(filterGroups: FilterGroups[]): FilterGroups {
	const filters: FilterGroups = {};

	filterGroups
		.filter((v) => v)
		.forEach((f) => {
			filters[Object.keys(f)[0]] = {
				...Object.values(f)[0],
			};
		});

	return filters;
}
