import { t } from '@transifex/native';
import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';

import spacing from 'pkg/config/spacing';

import { Features } from 'pkg/models/group';

import * as models from 'pkg/api/models';
import { replaceState } from 'pkg/router/state';
import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import { link } from 'pkg/router/utils';
import { newChatParams } from 'pkg/chat';

import AddToGroupsModal from 'containers/user/AddToGroupsModal';

import { useSmallScreen } from 'components/MediaQuery';

import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';
import { useDialog } from 'design/dialog';

interface ContactActionsProps {
	group: models.group.Group;
	contact: models.user.User;
	canChat: boolean;
	openModal: (modalName: string) => void;
	refreshMemberships: () => void;
}

export default function ContactActions({
	group,
	contact,
	canChat,
	openModal,
	refreshMemberships,
}: ContactActionsProps) {
	const [showModal, setShowModal] = useState(false);
	const organization = useCurrentOrganization();

	const handleArchive = async () => {
		await actions.users.archiveUsers([contact.id])(store.dispatch);

		replaceState(routes.Management.Contacts.Home(organization.id));
	};

	const handleRemoveBilling = async () => {
		await actions.users.update(contact.id, { billingUserId: null })(
			store.dispatch
		);
	};

	let archiveMessage = t('Are you sure you want to archive this contact?');

	if (contact.accountId) {
		archiveMessage = t(
			'Important: {user} has an active account. If archived {firstName} will lose access to the account in {organization}.',
			{
				user: models.user.fullName(contact),
				firstName: contact.firstName,
				organization: organization.name,
			}
		);
	}

	const archiveDialog = useDialog({
		message: archiveMessage,
		onConfirm: handleArchive,
	});

	const isSmallScreen = useSmallScreen();

	const handleAddToGroups = async (groups: { role: number; id: number }[]) => {
		const ok = await actions.memberships.addUserToGroups(contact.id, groups);

		if (ok) {
			refreshMemberships();
		}
	};

	const handleOpenModal = () => setShowModal(true);

	const handleCloseModal = () => setShowModal(false);

	return (
		<Fragment>
			<Row spacing={isSmallScreen ? spacing._2 : spacing._1}>
				<Button
					small
					secondary
					iconPosition="top"
					icon="edit"
					block
					href={routes.Management.Contact.Edit(organization.id, contact.id)}
					testid="contacts.single.edit"
					label={t('Edit')}
				/>
				{models.group.hasFeature(group, Features.Payments) && (
					<Button
						small
						secondary
						iconPosition="top"
						icon="request_quote"
						block
						href={link(routes.Invoice.New(organization.id), {
							userIds: [contact.id],
						})}
						label={t('Invoice')}
					/>
				)}
				<Button
					small
					secondary
					iconPosition="top"
					icon="group_add"
					block
					onClick={handleOpenModal}
					testid="contacts.single.add_to_group"
					label={t('Group')}
				/>
				<ContextMenu.Menu
					toggleWith={
						<Button
							secondary
							small
							iconPosition="top"
							icon="more_horiz"
							block
							testid="contacts.single.actions">
							{t('More')}
						</Button>
					}>
					{models.group.hasFeature(group, Features.Payments) && (
						<ContextMenu.Item
							onClick={() => openModal('product')}
							testid="contacts.single.assign_product">
							<ContextMenu.ItemIcon name="sell" />
							{t('Assign product')}
						</ContextMenu.Item>
					)}
					{canChat && (
						<ContextMenu.LinkItem
							href={link(
								routes.Chat.New(organization.id),
								newChatParams([contact])
							)}>
							<ContextMenu.ItemIcon name="comment" />
							{t('New chat')}
						</ContextMenu.LinkItem>
					)}
					{contact.email && (
						<ContextMenu.LinkItem href={`mailto:${contact.email}`}>
							<ContextMenu.ItemIcon name="mail" />
							{t('Send email')}
						</ContextMenu.LinkItem>
					)}
					<ContextMenu.Item caution onClick={archiveDialog}>
						<ContextMenu.ItemIcon name="archive" />
						{t('Archive contact')}
					</ContextMenu.Item>
					<ContextMenu.Divider />
					<ContextMenu.Label>{t('Billing contact')}</ContextMenu.Label>
					{contact.billingUserId && (
						<ContextMenu.LinkItem
							href={routes.Management.Contact.Show(
								organization.id,
								contact.billingUserId,
								'overview'
							)}>
							<ContextMenu.ItemIcon name="arrow_forward" />
							{t('View billing contact')}
						</ContextMenu.LinkItem>
					)}
					<ContextMenu.Item onClick={() => openModal('selectContact')}>
						<ContextMenu.ItemIcon name="person" />
						{t('Select billing contact')}
					</ContextMenu.Item>
					{contact.billingUserId && (
						<ContextMenu.ConfirmItem
							onConfirm={handleRemoveBilling}
							caution
							data-testid="contacts.single.remove_billing_contact"
							message={t('Remove billing contact?')}>
							<ContextMenu.ItemIcon name="remove" />
							{t('Remove billing contact')}
						</ContextMenu.ConfirmItem>
					)}
				</ContextMenu.Menu>
			</Row>
			{showModal && (
				<AddToGroupsModal
					groupId={organization.id}
					userId={contact.id}
					onConfirm={handleAddToGroups}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
}
