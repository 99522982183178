import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';

import StatusScreen from 'components/status-screen';

import Button from 'design/button';

const Cancel = () => {
	const route = useCurrentRoute();
	const formSubmissionId = route?.formSubmissionId;

	const cancelFormSubmission = async () => {
		if (formSubmissionId) {
			const response = await sdk.post(
				endpoints.FormSubmissions.CancelFormSubmission(
					parseInt(formSubmissionId)
				)
			);
			if (!response.ok) {
				flashActions.show(
					{
						title: t('Your form submission has been cancelled.'),
					},
					response.status
				);
			}
		}
	};

	useComponentDidMount(() => {
		cancelFormSubmission();
	});

	return (
		<StatusScreen
			fullscreen
			status="cancel"
			title={t('Form submission cancelled!')}
			description={t(
				'The form submission was cancelled and your response was not recorded.'
			)}>
			<Button primary large href={routes.Home()}>
				{t('Go to home')}
			</Button>
		</StatusScreen>
	);
};

export default Cancel;
