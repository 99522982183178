import { Author } from 'pkg/api/models/author';
import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import { Record } from 'pkg/api/models/record';
import { Group } from 'pkg/api/models/group';
import { Exercise } from 'pkg/api/models/exercise';
import { Session } from 'pkg/api/models/session';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';

export interface TrainingCollection extends Linkable, Record, Dateable {
	groupId: number;
	userId: number;
	accountId: number;
	name: string;
	userCreated: boolean;
	inherit: boolean;
	exerciseCount: number;
	sessionCount: number;

	group: Group;
	author: Author;
}

export interface TrainingCollectionExercise extends Record, Dateable {
	exerciseId: number;
	collectionId: number;

	exercise: Exercise;
}

export interface TrainingCollectionSession extends Record, Dateable {
	sessionId: number;
	collectionId: number;

	session: Session;
}

export function isNew(collection: TrainingCollection): boolean {
	return collection.createdAt + 3600 * 24 > Math.floor(Date.now() / 1000);
}

export interface TrainingCollectionCreatePayload {
	name: string;
	groupId?: number;
	inherit?: boolean;
}

export async function create(
	organizationId: number,
	payload: TrainingCollectionCreatePayload
): Promise<[Response, TrainingCollection, models.APIError?]> {
	return models.create(
		endpoints.TrainingCollections.Create(organizationId),
		payload
	);
}

export interface TrainingCollectionUpdatePayload {
	name?: string;
	inherit?: boolean;
}

export async function update(
	collection: Linkable,
	payload: TrainingCollectionUpdatePayload
): Promise<[Response, TrainingCollection, models.APIError?]> {
	return models.update(collection, payload);
}

export async function addExercise(
	organizationId: number,
	collectionId: number,
	exerciseId: number
): Promise<[Response, TrainingCollectionExercise, models.APIError?]> {
	return models.create<null, TrainingCollectionExercise>(
		endpoints.TrainingCollections.AddExercise(
			organizationId,
			collectionId,
			exerciseId
		),
		null
	);
}

export async function removeExercise(
	organizationId: number,
	collectionId: number,
	exerciseId: number
): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.TrainingCollections.RemoveExercise(
			organizationId,
			collectionId,
			exerciseId
		)
	);

	return request.ok;
}

export async function addSession(
	organizationId: number,
	collectionId: number,
	sessionId: number
): Promise<[Response, TrainingCollectionSession, models.APIError?]> {
	return models.create<null, TrainingCollectionSession>(
		endpoints.TrainingCollections.AddSession(
			organizationId,
			collectionId,
			sessionId
		),
		null
	);
}

export async function removeSession(
	organizationId: number,
	collectionId: number,
	sessionId: number
): Promise<boolean> {
	const request = await sdk.destroy(
		endpoints.TrainingCollections.RemoveSession(
			organizationId,
			collectionId,
			sessionId
		)
	);

	return request.ok;
}
