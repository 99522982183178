import { useT } from '@transifex/react';

import { hideEventParticipantsFlag } from 'pkg/flags';

import * as Input from 'components/form/inputs';

interface HideParticipantsProps {
	value: boolean;
	handleHideParticipantsChange: () => void;
}

export default function HideParticipants({
	value,
	handleHideParticipantsChange,
}: HideParticipantsProps) {
	const t = useT();

	if (!hideEventParticipantsFlag()) {
		return null;
	}

	return (
		<Input.Group label={t('Participants visibility')}>
			<Input.Control
				type="checkbox"
				label={t('Hide participants')}
				description={t(
					'Toggle this setting to hide participants from eachother, organizers will still be able to see all participants and parents will be able to see their children.'
				)}
				checked={value}
				onChange={handleHideParticipantsChange}
			/>
		</Input.Group>
	);
}
