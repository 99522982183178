import { Fragment, createContext, useContext, useEffect } from 'react';
import { useT } from '@transifex/react';
import { Browser } from '@capacitor/browser';
import { useFormContext } from 'react-hook-form';

import spacing from 'pkg/config/spacing';

import { PaymentPreviewPayload, PaymentRow } from 'pkg/actions/payment_preview';

import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import { isApp } from 'pkg/platform';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';

import FormBilling from 'routes/forms/registration/form/billing';
import FormFields from 'routes/forms/registration/form/fields';
import FormProducts from 'routes/forms/registration/form/products';

import { useSmallScreen } from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';

import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';
import Form, { FormPayload } from 'components/form/Form';
import Column from 'components/layout/column';
import * as Inputs from 'components/form/inputs';

import Button, { ButtonGroup } from 'design/button';

interface RegistrationProps extends RegistrationWrapperProps {
	showSubmitButton: boolean;
	isSubmitting: boolean;
}
interface RegistrationWrapperProps {
	form: models.form.Form;
	submittedForUser: models.user.User;
	submittedForAccount: models.account.Account;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
	setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
	isSubmitting: boolean;
}

export interface Bundle {
	bundleNumber: number;
	installmentCount: number;
	recurringInterval: string;
	recurringIntervalCount: number;
	productPrices: models.productPrice.ProductPrice[];
	subscriptionStartsAt: number;
}

export interface ProductWithQty {
	id: number;
	name: string;
	minQty: number;
	maxQty: number;
}

enum FormSectionOrder {
	Fields = 1,
	Products = 2,
	Billing = 3,
}

const getBundlesAndProducts = (
	form: models.form.Form
): {
	bundles: { [key: number]: Bundle };
	requiredProducts: { [key: number]: ProductWithQty };
	optionalProducts: { [key: number]: ProductWithQty };
} => {
	const bundles: { [key: number]: Bundle } = {};
	const requiredProducts: { [key: number]: ProductWithQty } = {};
	const optionalProducts: { [key: number]: ProductWithQty } = {};

	if (form.productPrices) {
		for (const fpp of form.productPrices) {
			if (!bundles[fpp.bundleNumber]) {
				bundles[fpp.bundleNumber] = {
					bundleNumber: fpp.bundleNumber,
					installmentCount: fpp.installmentCount,
					recurringInterval: fpp.productPrice.recurringInterval,
					recurringIntervalCount: fpp.productPrice.recurringIntervalCount,
					productPrices: [fpp.productPrice],
					subscriptionStartsAt: fpp.subscriptionStartAt,
				};
			} else {
				bundles[fpp.bundleNumber].productPrices.push(fpp.productPrice);
			}

			const productWithQty = {
				id: fpp.productPrice.productId,
				name: fpp.productPrice.product.name,
				minQty: fpp.minQuantity,
				maxQty: fpp.maxQuantity,
			};
			if (fpp.required) {
				requiredProducts[fpp.productPrice.productId] = productWithQty;
			} else {
				optionalProducts[fpp.productPrice.productId] = productWithQty;
			}
		}
	}

	return { bundles, requiredProducts, optionalProducts };
};

export const getProductQtyName = (productId: number) =>
	`product_quantities.productId_${productId}`;

interface State {
	preview: models.paymentPreview.PaymentPreview;
	bundlePreviews: models.paymentPreview.PaymentPreview[];
	discount: models.discount.Discount;
	activeSection: FormSectionOrder;
	activeBundleNumber: number;
}
interface RegistrationFormContextProps {
	state: State;
	setState: MixedStateSetter<State>;
}

export const RegistrationFormContext =
	createContext<RegistrationFormContextProps>({
		state: {
			preview: null,
			bundlePreviews: [],
			discount: null,
			activeSection: FormSectionOrder.Fields,
			activeBundleNumber: 1,
		},
		setState: () => null,
	});

export function useRegistrationFormContext() {
	return useContext(RegistrationFormContext);
}

const RegistrationFormWrapper = ({
	form,
	submittedForUser,
	submittedForAccount,
	paymentProviderSettings,
	setIsSubmitting,
	isSubmitting,
}: RegistrationWrapperProps) => {
	const [state, setState] = useMixedState<State>({
		preview: null,
		bundlePreviews: [],
		discount: null,
		activeSection: FormSectionOrder.Fields,
		activeBundleNumber: 1,
	});

	const showSubmitButton = form.productPrices.length
		? state.activeSection >= FormSectionOrder.Billing
		: state.activeSection === FormSectionOrder.Fields;

	const group = useCurrentGroup();

	const handleSubmit = async (data: FormPayload) => {
		if (isSubmitting) {
			return;
		}

		if (!showSubmitButton) {
			setState({
				activeSection: state.activeSection + 1,
			});

			return;
		}

		setIsSubmitting(true);

		const organizationId = models.group.getOrganizationId(group);

		const req = await actions.formSubmissions.create({
			form,
			data,
			organizationId,
			discount: state.discount,
		});

		if (req.ok) {
			const res = await req.json();
			if (isApp() && res.checkoutUrl) {
				await Browser.open({ url: res.checkoutUrl });
				Browser.addListener('browserFinished', () => {
					const paymentStatusUrl =
						routes.Registrations.Register.PaymentStatus(
							organizationId,
							form.groupId,
							form.id
						) + `?formSubmissionId=${res.formSubmission.id}`;
					replaceState(paymentStatusUrl);
				});
			} else if (res.checkoutUrl) {
				window.location.assign(res.checkoutUrl);
			} else {
				replaceState(
					routes.Registrations.Register.Success(
						organizationId,
						form.groupId,
						form.id
					)
				);
			}
		} else {
			replaceState(
				routes.Registrations.Register.Cancel(
					organizationId,
					form.groupId,
					form.id,
					null
				)
			);
		}
	};
	return (
		<RegistrationFormContext.Provider value={{ state, setState }}>
			<Form onSubmit={handleSubmit}>
				<RegistrationForm
					form={form}
					submittedForAccount={submittedForAccount}
					submittedForUser={submittedForUser}
					paymentProviderSettings={paymentProviderSettings}
					showSubmitButton={showSubmitButton}
					isSubmitting={isSubmitting}
					setIsSubmitting={setIsSubmitting}
				/>
			</Form>
		</RegistrationFormContext.Provider>
	);
};

const RegistrationForm = ({
	form,
	submittedForUser,
	submittedForAccount,
	paymentProviderSettings,
	showSubmitButton,
	isSubmitting,
}: RegistrationProps) => {
	const isSmallScreen = useSmallScreen();
	const t = useT();
	const group = useCurrentGroup();
	const registrationCtx = useRegistrationFormContext();

	const handlePrev = () => {
		registrationCtx.setState({
			activeSection: registrationCtx.state.activeSection - 1,
		});
	};

	const { bundles, requiredProducts, optionalProducts } =
		getBundlesAndProducts(form);

	const { watch, setValue } = useFormContext();
	const productQtys: { [key: number]: number } = {};

	for (const product of Object.values(requiredProducts)) {
		productQtys[product.id] =
			Number.parseInt(watch(getProductQtyName(product.id)), 10) ||
			product.minQty;
	}

	for (const product of Object.values(optionalProducts)) {
		productQtys[product.id] =
			Number.parseInt(watch(getProductQtyName(product.id)), 10) || 0;
	}

	const showPrefilledInfo = !!form.fields.find((field) => {
		if (
			field.userFieldId ||
			field.key.includes('user_') ||
			field.key.includes('billing_')
		) {
			return true;
		}

		return false;
	});

	const products = [
		...Object.values(requiredProducts),
		...Object.values(optionalProducts),
	];

	const FormButtons = () => {
		const isProductsOrBillingSection =
			registrationCtx.state.activeSection === FormSectionOrder.Products ||
			registrationCtx.state.activeSection === FormSectionOrder.Billing;

		const shouldWrapInRow = isProductsOrBillingSection && !isSmallScreen;

		const buttons = (
			<ButtonGroup justifyContent={isSmallScreen ? 'stretch' : 'end'}>
				{registrationCtx.state.activeSection == 1 && (
					<Button
						large
						block={isSmallScreen}
						href={routes.User.Registrations(group.organizationId, 'open')}>
						{t('Cancel')}
					</Button>
				)}
				{registrationCtx.state.activeSection > 1 && (
					<Button large block={isSmallScreen} onClick={handlePrev}>
						{t('Back')}
					</Button>
				)}

				{showSubmitButton ? (
					<Button
						primary
						large
						block={isSmallScreen}
						isLoading={isSubmitting}
						type="submit">
						{form.productPrices.length ? t('Proceed to payment') : t('Submit')}
					</Button>
				) : (
					<Button primary large block={isSmallScreen} type="submit">
						{t('Next')}
					</Button>
				)}
			</ButtonGroup>
		);

		return shouldWrapInRow ? (
			<Row columns="3fr 1.5fr" spacing={spacing._7} collapseOnSmallScreens>
				{buttons}
			</Row>
		) : (
			buttons
		);
	};

	useEffect(() => {
		const fetchCartData = async () => {
			if (form?.groupId && form.productPrices.length) {
				const activeBundle = bundles[registrationCtx.state.activeBundleNumber];

				const payload: PaymentPreviewPayload = {
					groupId: form.groupId,
					rows: activeBundle.productPrices.map((pp) => {
						const product = products.find((prod) => prod.id === pp.productId);

						const formProductPrice = form.productPrices.find(
							(fpp) =>
								fpp.bundleNumber == registrationCtx.state.activeBundleNumber &&
								fpp.productPriceId == pp.id
						);

						const row: PaymentRow = {
							productPriceId: pp.id,
							quantity: productQtys[product?.id],
						};

						if (formProductPrice?.taxRateId) {
							row.taxRateId = formProductPrice.taxRateId;
						}

						return row;
					}),
					bundleNumber: registrationCtx.state.activeBundleNumber,
				};

				if (activeBundle.subscriptionStartsAt) {
					payload.subscriptionStartAt = activeBundle.subscriptionStartsAt;
					payload.delayedSubscriptionStart = true;
				}

				if (activeBundle.installmentCount) {
					payload.installmentCount = activeBundle.installmentCount;
				}

				if (registrationCtx.state.discount) {
					payload.discountId = registrationCtx.state.discount.id;
				}

				const [req, resp] = await actions.paymentPreview.getPreview(payload);

				if (req) {
					registrationCtx.setState({
						preview: resp,
					});
				}
			}
		};

		fetchCartData();
	}, [
		form?.groupId,
		products.length,
		registrationCtx.state.activeBundleNumber,
		JSON.stringify(productQtys),
		JSON.stringify(registrationCtx.state.discount),
	]);

	useEffect(() => {
		const fetchBundlesData = async () => {
			if (form.id && form.productPrices.length) {
				const payload: PaymentPreviewPayload[] = Object.values(bundles).map(
					(bundle) => {
						const preview: PaymentPreviewPayload = {
							groupId: form?.groupId,
							bundleNumber: bundle.bundleNumber,
							rows: bundle.productPrices.map((pp) => {
								const product = products.find(
									(prod) => prod.id === pp.productId
								);

								const formProductPrice = form.productPrices.find(
									(fpp) =>
										fpp.bundleNumber ==
											registrationCtx.state.activeBundleNumber &&
										fpp.productPriceId == pp.id
								);

								const row: PaymentRow = {
									productPriceId: pp.id,
									quantity: productQtys[product?.id],
								};

								if (formProductPrice?.taxRateId) {
									row.taxRateId = formProductPrice.taxRateId;
								}

								return row;
							}),
						};

						if (bundle.subscriptionStartsAt) {
							preview.subscriptionStartAt = bundle.subscriptionStartsAt;
							preview.delayedSubscriptionStart = true;
						}

						if (bundle.installmentCount) {
							preview.installmentCount = bundle.installmentCount;
						}

						return preview;
					}
				);

				const [req, resp] =
					await actions.paymentPreview.getBundlesPreviews(payload);

				if (req) {
					registrationCtx.setState({
						bundlePreviews: resp,
					});
				}
			}
		};

		fetchBundlesData();
	}, [
		form.id,
		registrationCtx.state.activeBundleNumber,
		JSON.stringify(productQtys),
	]);

	// and we could possible have the cart/summary defined here once and reused for products and billing
	return (
		<Fragment>
			<Column>
				<Column spacing={spacing._8}>
					<SectionTitle large>{form.title}</SectionTitle>
					{registrationCtx.state.activeSection === FormSectionOrder.Fields && (
						<Column spacing={spacing._8}>
							{showPrefilledInfo && submittedForUser && (
								<InfoBox
									color="blue"
									text={t(
										'Some information regarding {firstName} has been pre-filled. To edit that information, please do so by editing the user profile',
										{
											firstName: submittedForUser.firstName,
										}
									)}
								/>
							)}
							<FormFields
								fields={form.fields}
								user={submittedForUser}
								account={submittedForAccount}
							/>
						</Column>
					)}
					{registrationCtx.state.activeSection ===
						FormSectionOrder.Products && (
						<FormProducts
							form={form}
							paymentProviderSettings={paymentProviderSettings}
							bundles={bundles}
							requiredProducts={requiredProducts}
							optionalProducts={optionalProducts}
							setValue={setValue}
							productQtys={productQtys}
						/>
					)}
					{registrationCtx.state.activeSection === FormSectionOrder.Billing && (
						<FormBilling
							fields={form.fields}
							user={submittedForUser}
							group={form.group}
						/>
					)}
				</Column>
				<Inputs.Field
					type="hidden"
					name="submittedForAccountId"
					value={
						submittedForUser
							? submittedForUser.accountId
							: submittedForAccount.id
					}
				/>
				<FormButtons />
			</Column>
		</Fragment>
	);
};

export default RegistrationFormWrapper;
