import { Fragment } from 'react/jsx-runtime';
import { ReactNode } from 'react';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

interface UserProfileProps {
	organizationId: number;
	userId: number;

	setCollapsibleContent: (node: ReactNode) => void;
}

export default function UserProfile({
	setCollapsibleContent,
}: UserProfileProps): JSX.Element {
	useComponentDidMount(() => {
		setCollapsibleContent(<div>Collapsible Content</div>);
	});

	return <Fragment>Hello World!</Fragment>;
}
